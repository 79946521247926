.map-container {
    width: 1500px;
    right: 0;
    height: 100vh;
  }
  
  .sidebarStyle {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    background-color: #404040;
    color: #ffffff;
    z-index: 1 !important;
    padding: 6px;
    font-weight: bold;
  }
  
  .marker {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ef44441a; /* Changed from #22c55e1a to a light red */
    padding: 4px;
}
  
.marker::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #ef4444; /* Changed from #22c55e to red */
    border-radius: 50%;
    animation: blinkMarker 1s infinite;
}
  @keyframes blinkMarker {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }